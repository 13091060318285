import van from "vanjs-core"
import {route} from "./components/Router";
import PrimaryButton from "./components/PrimaryButton";
import { NavBar } from "./components/NavBar";

const {div, button, a, t, h1} = van.tags;

const NoMembershipPage = () => {
  return div(
    {class: "flex flex-col h-full p-5"},
    NavBar(),
    div(
      {class: "flex flex-col w-full h-full justify-center items-center gap-10"},
      h1(
        {class: "font-rope font-semibold text-4xl"},
        "Not a Member"
      ),
      t(
        {class: "font-rope text-xl"},
        "You or your group must be a member of casenow to access non-demo cases"
      ),
      PrimaryButton({
        href: "/",
        text: "Back"
      })
    ),
  )
}

export default NoMembershipPage;