import van from "vanjs-core"
import { maxDemoCaseNumber, maxCaseNumber, isMember} from "../globals"
import { demoCaseNumber } from "../globals";

const {div, button, t, span} = van.tags;

const ProblemPicker = ({onProblemChosen, currentNumber}) => {
  
  const questionsDropdownVisible = van.state(false);

  return div(
    {class: "flex flex-col min-w-[150px]"},
    button(
      {
        class: "font-rope text-sm sm:text-md font-semibold hover:bg-steel p-2 rounded-lg  border-4 border-dark",
        onclick: ()=>{
          questionsDropdownVisible.val = !questionsDropdownVisible.val;
        }
      },
      div(
        {class: "flex flex-row gap-1 justify-center"},
        div({class: "flex flex-grow"}),
        t(
          {class: ""},
          `Problem #${currentNumber}  `
        ),
        div({class: "flex flex-grow"}),
        () => !questionsDropdownVisible.val ? span(
          {class: "material-symbols-outlined"},
          "expand_circle_down"
        ) : "",
        () => questionsDropdownVisible.val ? span(
          {class: "material-symbols-outlined"},
          "expand_circle_up"
        ) : "",
      )
    ),
    div({class: "h-4"}),
    div(
      {class: "position: relative"},
      () => {
        if (!questionsDropdownVisible.val){
          return ""
        }
        let questionsDropdownContents = div({class: "p-2 text-dark bg-light z-10 flex flex-col absolute w-full border-4 border-dark rounded-lg max-h-[50vh] overflow-y-scroll overflow-x-hidden"});
        for (let x = 1; x <= maxCaseNumber.val; ++x){
          van.add(
            questionsDropdownContents,
            button(
              {class: `font-rope ${!isMember.val && x > maxDemoCaseNumber.val ? "text-steel" : ""} text-sm sm:text-md p-1 w-full rounded-lg ${currentNumber == x ? "bg-dark text-light": "hover:bg-steel"}`, 
                onclick: currentNumber != x ? ()=>{
                  questionsDropdownContents.innerHTML = "";
                  questionsDropdownVisible.val = false;
                  onProblemChosen(x);
                } : ()=>{}
              },
              `Problem #${x}`
            )
          )
        }
        return questionsDropdownContents;
      },
    )
  )
}

export {ProblemPicker}