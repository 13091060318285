import van from "vanjs-core"
import TbdPage from "../TbdPage";
import CasePage from "../CasePage";
import HomePage from "../HomePage";
import { specifiedCaseNumber } from "../globals";
import { ProfilePage } from "../ProfilePage";
import { globalUser } from "../globals";
import NoMembershipPage from "../NoMembership";
import ExpiredPage from "../ExpiredPage";
import NoAccessPage from "../NoAccessPage";

const {t, div} = van.tags;

let url = van.state(document.location.pathname);
let blockRefresh = van.state(false);

let canInitState = van.state(true);

let urlParams = van.state([]);


const routes = {
  "/" : HomePage,
  "/case" : CasePage,
  "/specific-case": CasePage,
  "/profile": ProfilePage,
  "/tbd" : TbdPage,
  "/nomembership": NoMembershipPage,
  "/expired": ExpiredPage,
  "/noaccess": NoAccessPage
}


const routeToTitle = {
  "/" : "casenow",
  "/case" : "Case In Progress",
  "/profile": "Your Profile"
}

const Router = () => {

  console.log("Re routing!!!!");
  let parsed = van.derive(() => "/" + url.val.split("/")[1]);
  let secondParam = van.derive(()=> url.val.split("/").length > 2 ? url.val.split("/")[2] : "");
  if (parsed.val in routes){
    if (parsed.val == "/case" && secondParam.val != ""){
      specifiedCaseNumber.val = parseInt(secondParam.val);
    }
    return div(
      {class: "flex flex-col h-full"},
      ()=>routes[parsed.val]()
    );
  }
  return t(() => secondParam.val);
}

const route = (href) => {
  window.speechSynthesis.cancel();
  console.log("Routed to: ", href)
  if (href in routeToTitle){
    console.log("Changing title");
    document.title = routeToTitle[href];
  }
  window.onbeforeunload = null
  if (url.val == href){
    return;
  }
  if (href == "/case"){
    specifiedCaseNumber.val = -1;
  }
  url.val = href;
  window.history.pushState('page2', 'Title', href);
}


export {Router, route, url, canInitState};