import van from "vanjs-core"
import HomeHero from "./HomeHero";
import PrimaryButton from "./components/PrimaryButton";
import {SmallLink, SmallLinkButton} from "./components/SmallLink";
import {signInUserWithGoogle, isSignedIn, globalUserExam} from "./globals";
import { ExamInvite } from "./components/ExamInvite";

const {div, button, a, t} = van.tags;

const HomePage = () => {
  return div(
      {class: "flex justify-center flex-col h-full p-5"},
      div(
        {class: "w-full align-middle items-center flex flex-col"},
        HomeHero(),
        div({class: "h-[50px]"}),
        () => globalUserExam?.val != null ? ExamInvite() : "",
        () => globalUserExam?.val != null ? div({class: "h-[50px]"}) : "",
        PrimaryButton({
          href: `/case`,
          text: "Start a Case",
        }),
        div({class: "h-[50px]"}),
        () => !isSignedIn() ? 
        div(
          {class: "flex gap-2"},
          SmallLinkButton({onclick: () => signInUserWithGoogle(), text: "Log In"}),
          t("|"),
          SmallLinkButton({onclick: () => signInUserWithGoogle(), text: "Sign Up"})
        ) : "",
        
      ),
    );
}

export default HomePage;