import van from "vanjs-core"

const {div, button, t} = van.tags;

const ConfirmationModal = ({onCancel, onConfirm, label}) => {
  return div(
    {class: "modal w-full h-full flex justify-center items-center z-10 absolute top-0 left-0 bg-dark bg-opacity-50", style: "backdrop-filter: blur(10px);"},
    div({class: "w-[80%] max-w-[500px] flex flex-col p-5 bg-light border-french border-8 rounded-xl slide-in-elliptic-top-fwd"},
      label,
      div({class: "h-4"}),
      div({class: "flex"},
        button(
          {
            class: "p-2 max-w-[200px] text-base pl-4 pr-4 rounded-lg font-semibold font-rope  text-white hover:text-dark bg-dark hover:bg-gold", 
            onclick: onCancel
          },
          "Cancel",
        ),
        div({class: "flex flex-grow"}),
        button(
          {
            class: "p-2 text-base max-w-[200px] pl-4 pr-4 rounded-lg font-semibold font-rope  text-white bg-dark hover:bg-fire hover:text-light", 
            onclick: onConfirm,
          },
          "Continue",
        ),
      ),
    ),
  )
}

export {ConfirmationModal}