import van from "vanjs-core"
import {route} from "./Router"
const {button, a, div} = van.tags

const PrimaryButton = ({href, text, onclick}) => {
  return div(
      {class: "flex justify-center p-2 max-w-[200px] pl-4 pr-4 rounded-lg font-semibold font-rope text-2xl h-[50px]"},
      button(
        {class: "p-2 max-w-[200px]z-0 pl-4 pr-4 rounded-lg font-semibold font-rope text-2xl text-gold bg-gold absolute"},
        text
      ),
      button(
        {class: "p-2 max-w-[200px] z-0 pl-4 pr-4 rounded-lg font-semibold font-rope text-2xl text-white bg-dark hover:translate-y-[-5px] absolute", onclick: onclick !== undefined ? onclick : () => route(href)},
        text
      ),
    );
}

export default PrimaryButton;