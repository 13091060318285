import van from "vanjs-core"
import {route} from "./Router"

const {a, button} = van.tags;

const SmallLink = ({href, text})=>{
  return a(
    {class: "font-rope font-dark hover:underline underline-offset-8", href: href, 
      onclick:(e)=>{e.preventDefault(); route(href)}},
    text
  );
}

const SmallLinkButton = ({onclick, text})=>{
  return button(
    {
      class: "font-rope font-dark hover:underline underline-offset-8",
      onclick: onclick
    },
    text
  );
}

export {SmallLink, SmallLinkButton}