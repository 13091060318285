import van from "vanjs-core"
import {FeedbackRow} from "./FeedbackRow.js";
import PrimaryButton from "./PrimaryButton.js";
import { SmallLinkButton } from "./SmallLink.js";
import { isSignedIn, signInUserWithGoogle } from "../globals.js";
import Chart from 'chart.js/auto';

const {div, h1, t, canvas} = van.tags;

const FeedbackDisplay = (feedbackData) => {


  function waitForElm(selector) {
      return new Promise(resolve => {
          if (document.querySelector(selector)) {
              return resolve(document.querySelector(selector));
          }

          const observer = new MutationObserver(mutations => {
              if (document.querySelector(selector)) {
                  observer.disconnect();
                  resolve(document.querySelector(selector));
              }
          });

          // If you get "parameter 1 is not of type 'Node'" error, see https://stackoverflow.com/a/77855838/492336
          observer.observe(document.body, {
              childList: true,
              subtree: true
          });
      });
  }

  let responseTimesSaved = feedbackData.responseTimes;
  waitForElm("#chart").then((el) => {
    console.log("HERE NOW.", responseTimesSaved)

    const labels = Array.from({ length: responseTimesSaved.length + 1 }, (_, i) => i);
    const data = {
      labels: labels,
      datasets: [{
        label: 'Response Times (s)',
        data: responseTimesSaved,
        fill: false,
        borderColor: 'rgb(190, 208, 238)',
        tension: 0.1
      }]
    };
    new Chart(
      document.getElementById('chart'),
        {
          type: 'line',
          data: data,
          labels: labels,
          options: {
            responsive: false,
            scales: {
                y: {
                    beginAtZero: true
                }
            }
          }
        }
      );
  });

  let avgResponseTime = -1;
  let responseTimeDisplayed = "";
  if ("responseTimes" in feedbackData){
    avgResponseTime = 0;
    for (let x of feedbackData.responseTimes){
      avgResponseTime += x;
    }
    avgResponseTime /= feedbackData.responseTimes.length;
    delete feedbackData.responseTimes;
  }
  if (avgResponseTime != -1){
    responseTimeDisplayed = div({class: "flex justify-center"},
      h1(
        {class: "font-rope font-semibold text-xl"},
        `Average Response Time: ${avgResponseTime.toFixed(2)} s`
      ),
    )
  }

  let total = 0;
  let score = 0;
  let feedbackItems =  div({class: "w-[90%] sm:w-[70%] flex flex-col gap-1"});
  for (const [rubricItem, feedbackObj] of Object.entries(feedbackData)) {
      van.add(feedbackItems, FeedbackRow({
        criterion: rubricItem,
        score:  `${feedbackObj.awarded} / ${feedbackObj.points}`,
        description: feedbackObj.feedback
      }))
      total += feedbackObj.points ?? 0;
      score += feedbackObj.awarded ?? 0;
  }
  return div({id: "feedbackContent", class: "flex-col h-full"},
    div({class: "h-5"}),
    div({class: "flex justify-center"},
      h1(
        {class: "font-rope font-semibold text-4xl"},
        `Your Score: ${score} / ${total}`
      ),
    ),
    responseTimeDisplayed,
    div({class: "w-full flex justify-center"},
      canvas({
      id:"chart",
      class: "w-[400px] h-[400px]"
    }),
    ),
    div({class: "h-5"}),
    div({class: "w-full flex justify-center"},
      feedbackItems
    ),
    div({class: "h-4"}),
    () => !isSignedIn() ? div(
      {class: "flex justify-center"},
      SmallLinkButton({
        "onclick": signInUserWithGoogle,
        "text": "Log in to save your progress",
      })
    ) : "",
    div({class: "h-4"}),
    div(
      {class: "flex justify-center"},
      PrimaryButton({
        href: "/",
        text: "Back"
      })
    )
  )
}

export {FeedbackDisplay}