import van from "vanjs-core"
import {route} from "./Router"
import { isSignedIn , globalUser} from "../globals";
import { ProfileCircle } from "./ProfileCircle";
import { ConfirmationModal } from "./ConfirmationModal";
const {div, a, img, t, br} = van.tags;

const NavBar = (showConfirmationFirst = false) => {
  const imageUrl = new URL(
    '../../images/logo.png',
    import.meta.url
  );
  let showConfirmationModal = van.state(false);
  return div(
    {class: "flex w-full items-center p-1 flex-grow-0 font-semibold text-dark text-2xl"},

    () => showConfirmationModal.val ? ConfirmationModal({
    onCancel: () => showConfirmationModal.val = false,
    onConfirm: () => route("/"),
    label: div(
          t({class: "text-dark font-semibold text-2xl font-rope"},
          "Are you sure?",
          ),          
          div({class: "h-4"}),
          t({class: "text-dark font-rope font-normal text-base"},
            "Going to Home will quit the case WITHOUT SAVING.",
          ),
          br(),
          t({class: "text-dark text-semibold font-rope text-base"},
            "Please Note: Your timer is not paused right now!",
          ),
          div({class: "h-4"}),
        ),
  }) : "",
      a({class: "font-rope", href: "/", onclick: (e)=>{
          e.preventDefault();
          if (showConfirmationFirst){
            showConfirmationModal.val = true;
            return;
          }
          route("/")
      }},
      div({class: "flex items-center gap-2"},
        img({src: imageUrl, class: "w-10 h-auto"}),
        t(
          "casenow",
        ),
      ),
    ),
    div({class: "w-full flex flex-grow"}),
        () => isSignedIn() ?  ProfileCircle(showConfirmationFirst) : ""
  );
}

export {NavBar};