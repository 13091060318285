import van from "vanjs-core"
import PrimaryButton from "./components/PrimaryButton";
import { calculateSpeechDuration  } from "./helpers/speechHelpers";
import { formatElapsedTimeForCasing } from "./helpers/formatting";
import { formatMessageForChat } from "./helpers/formatting";
import { ConfirmationModal } from "./components/ConfirmationModal";
import { FeedbackDisplay } from "./components/FeedbackDisplay";
import { NavBar } from "./components/NavBar";

import { demoCaseNumber, getDemoCaseData, maxDemoCaseNumber, speechRecognition, setKeyDownListener, setKeyUpListener, specifiedCaseNumber, globalUser, isSignedIn} from "./globals";
import { ProblemPicker } from "./components/ProblemPicker";

import {route} from "./components/Router";
import isMobileOrTablet from "./helpers/checkDevice";
import { getAuth, getIdToken } from "firebase/auth";
import { SmallLinkButton } from "./components/SmallLink";

let globalInterval;


const {div, h1, button, audio, t, textarea, br, span} = van.tags;

const CasePage = () => {
  const loading = van.state(true);
  const progressLabel = van.state("");
  const progressAmount = van.state(0);

  const highAudioQuality = van.state(false);

  const chatOutput = van.state("");
  const listening = van.state(false);

  let inputPlaceholderDefault = "Start Typing Here or Hold Up Arrow Key to Talk";
  if (isMobileOrTablet()){
    inputPlaceholderDefault = "Start Typing Here"
  }
  const inputDisabled = van.state(true);
  const inputPlaceholder = van.state(inputPlaceholderDefault);
  const inputVal = van.state("");

  const timerInterval = van.state(-1);
  const timerTime = van.state(0);
  const timerText = van.state("");
  const timerShow = van.state(false);

  let chatOutputEl = div({class: "border-dark border-l-4 sm:pl-10 sm:pr-10 pl-2 pr-2 font-rope"});
  
  const exitModalShow = van.state(false);
  const feedbackShow = van.state(false);
  const feedbackData = van.state({});

  let listenIndicator = div({class: "pulsate-fwd p-2 max-w-[200px] pl-4 pr-4 rounded-lg font-semibold font-rope  text-dark"}, "((( Listening )))")
  const listenIndicatorShow = van.state(false);
  const listenTimer = van.state(0);

  const muted = van.state(false);

  const submitResponse = async () => {
    console.log(inputVal.val);
    let savedInput = document.getElementById("mainInput").value;
    inputVal.val = "";
    inputDisabled.val = true;
    inputPlaceholder.val = "Waiting for interviewer...";

    addMessageToChat("You", savedInput, formatElapsedTimeForCasing(timerTime.val));

    timerShow.val = false;
    clearInterval(globalInterval);
    timerTime.val = 0;

    let rawRes = await fetch("/api/complete", {
      method: "POST",
      headers:{
          "Content-Type": "application/json" 
      },
      body: JSON.stringify({
        message: savedInput,
        highAudioQuality: highAudioQuality.val
      })
    });
    let parsedRes = await rawRes.formData();
    handleInterviewerMsg(parsedRes);

  }

  const addMessageToChat = (heading, msg, marking="") => {
    van.add(
      chatOutputEl,
      t(
        {class: "font-semibold font-rope"},
        heading
      ),
      div(
        {class: "flex w-full"},
        t(
          {class: "pb-[10px]"},
          msg
        ),
        marking == "" ? null : div(
          {class: "flex-grow"}
        ),
        marking == "" ? null : t(
          {class: "font-semibold min-w-14 text-base text-end"},
          marking
        ),
      ),
      div(
        {class: "flex w-full font-rope"},
        div({class: "flex-grow"})
      ),
    );
  }

  const handleInterviewerMsg = async (res) => {
    console.log("HERE and changed");
    let f = [...res.entries()];
    let textData = await f[0][1].text();
    if (textData == "FORBIDDEN"){
      route("/nomembership");
      return;
    }
    if (textData == "NOACCESS"){
      route("/noaccess");
      return;
    }
    if (textData == "EXPIRED"){
      route("/expired");
      return;
    }
    addMessageToChat(
      "Interviewer", 
      formatMessageForChat(textData)
    )

    window.speechSynthesis.cancel();
    var msg = new SpeechSynthesisUtterance();
    msg.text = textData;
    setTimeout(() => {
        timerTime.val = 0;
        timerShow.val = true;
        clearInterval(globalInterval);
        globalInterval = setInterval(()=>{
            timerTime.val += 0.1;
            timerText.val = formatElapsedTimeForCasing(timerTime.val);
        }, 100);
    }, 0) //calculateSpeechDuration(textData))

    if (f.length > 1){
      let audioData = f[1][1]
      if (audioData) {
        const audioBlob = audioData;
        let reader = new FileReader();
        reader.onload = () => {
            document.getElementById("theAudio").src = reader.result.toString();
            document.getElementById("theAudio").play();
        };
        reader.readAsDataURL(audioBlob);
      }
      else if (!muted.val){
        window.speechSynthesis.speak(msg);
      }
    }
    else if (!muted.val){
      window.speechSynthesis.speak(msg);
    }

    inputDisabled.val = false;
    inputPlaceholder.val = inputPlaceholderDefault;
  }

  const startListening = () => {
    console.log("Listening");
    speechRecognition.start();
    listenTimer.val = setTimeout(()=>{
      listenIndicatorShow.val = true;
    }, 500);
  }
  
  const stopListening = () => {
    console.log("Saving!!");
    speechRecognition.stop();
    try{
      clearTimeout(listenTimer.val);
    }
    catch(e){
        console.log("couldn't clear timer");
    }
    console.log("Saving: " + document.getElementById("mainInput").value);
    inputVal.val =  document.getElementById("mainInput").value;
    console.log("Saving: " + inputVal.val);
    listenIndicatorShow.val = false;
    document.getElementById("mainInput").style.height = "1px";
    document.getElementById("mainInput").style.height = (25+document.getElementById("mainInput").scrollHeight)+"px"
  }

  const onResult = (event) => {
    let newOne = "";
    for (const res of event.results) {
      newOne += res[0].transcript;
    }
    if (newOne.trim() == "" && document.getElementById("mainInput").value.trim() != ""){
      return;
    }
    console.log("SAVING ON RESULT: " + newOne);
    document.getElementById("mainInput").value = newOne;
    if ( 100+document.getElementById("mainInput").scrollHeight > document.getElementById("mainInput").offsetHeight){
      document.getElementById("mainInput").style.height = "1px";
      document.getElementById("mainInput").style.height = (25+document.getElementById("mainInput").scrollHeight)+"px"
    }
    inputVal.val = document.getElementById("mainInput").value;
  }

  const initState = async () => {
    window.onbeforeunload = (event) => {
      event.preventDefault();
      return "Data will be lost if you leave the page, are you sure?";
    };
    await getDemoCaseData();
    console.log("GLOBAL USER: " + globalUser?.val?.uid)
    let token = await getIdToken(globalUser.val);
    console.log(token);
    console.log("HERE!!!");
    let firstRes = await fetch("/api/first-complete", {
      method: "POST",
      headers:{
          "Content-Type": "application/json" 
      },
      body: JSON.stringify({
        problemNumber: specifiedCaseNumber.val === -1 ? demoCaseNumber.val : specifiedCaseNumber.val,
        jwt: token,
        highAudioQuality: highAudioQuality.val
      })
    }).catch((e)=>{
      console.log("FAILED");
      console.log(e);
    });
    let parsed = await firstRes.formData();
    console.log("HERE!!");
    try{
      setKeyDownListener((event)=>{
        if (event.key != "ArrowUp" || listening.val){
          return;
        }
        listening.val = true;
        startListening();
      });
      setKeyUpListener((event)=>{
        document.getElementById("mainInput").style.height = "1px";
        document.getElementById("mainInput").style.height = (50+document.getElementById("mainInput").scrollHeight)+"px";
        if (event.key != "ArrowUp" || !listening.val){
          return;
        }
        listening.val = false;
        stopListening();
      });
    }
    catch(e){
      console.log(e);
    }

    loading.val = false;
    speechRecognition.addEventListener("result", onResult);
    handleInterviewerMsg(parsed);
    trigger.val = false;
  }

  const trigger = van.state(!isSignedIn());
  van.derive(() => trigger.val && initState())
  trigger.val = !trigger.val;

  return div(
    {class: "flex justify-center flex-col h-full p-5"},
    () => NavBar(!feedbackShow.val),
    () => exitModalShow.val ? 
    ConfirmationModal({
      onCancel : () => exitModalShow.val = false,
      onConfirm : () => {
        console.log("Here!!!");
        exitModalShow.val = false;
        loading.val = true;
        let progressCheckInterval = 0;
        fetch("/api/finalscore").then( async (res)=>{
          let data = await res.json();
          feedbackData.val = data;
          feedbackShow.val = true;
          loading.val = false;
          clearInterval(progressCheckInterval);
        });       
        progressCheckInterval = setInterval(async ()=>{
          console.log("Polling!");
          let res = await fetch("/api/scoreprogress");
          let data = await res.json();
          progressLabel.val = data["progress"][0].toString() + " / " + data["progress"][1].toString();
          progressAmount.val =  (data["progress"][0] / data["progress"][1]) * 100
        }, 2000);
      },
      label:
        div(
          t({class: "text-dark font-semibold text-2xl font-rope"},
          "Are you sure?",
          ),          
          div({class: "h-4"}),
          t({class: "text-dark -semibold font-rope"},
            "Exiting will end the case and result in scoring immediately.",
          ),
          br(),
          t({class: "text-dark text-semibold font-rope"},
            "Please Note: Your timer is not paused right now!",
          ),
          div({class: "h-4"}),
        ),
    }) : "",
    () =>{
      if (feedbackShow.val){
        return FeedbackDisplay(feedbackData.val);
      }
      return loading.val ? div(
        {class: "flex flex-col w-full h-full justify-center items-center gap-2"},
        div(
          {class: "flex gap-7"},
          div({class: "loader translate-y-[-10px]"}),
          h1(
            {class: "font-rope font-semibold text-4xl self-baseline"},
            "Loading..."
          ),
        ),
        div({class: "h-4"}),
        () => progressAmount.val != 0 ? t(
          {class: "font-rope font-semibold text-xl"},
          progressLabel.val
        ) : "",
        () => progressAmount.val != 0 ? 
        div({class: "w-[90%] sm:max-w-[500px] rounded-full h-2.5 bg-steel"},
          div({class: "bg-dark h-2.5 rounded-full", style: `width: ${progressAmount.val}%;`}),
        ) : ""
      ) : 
      div(
        {class: "flex flex-col h-full"},
        div( //mainContent
          {class: "flex justify-center flex-col h-full sm:p-5 p-2"},
          div({class: "flex flex-grow"}),
          div( //mainPart
            {class: "flex flex-col align-middle items-center"},
            div(
              {class: "w-full sm:max-w-[75%] flex flex-grow justify-end gap-1"},
              div(
                {class: "flex flex-col"},
                () => !muted.val && !isMobileOrTablet() ? button(
                  {class: "font-rope text-sm sm:text-md font-semibold bg-light text-dark p-2 rounded-lg border-4 border-dark", onclick: () => {
                    muted.val = true;
                    window.speechSynthesis.cancel();
                  }},
                  div(
                    {class: "flex flex-row gap-1"},
                    t(
                      {class: ""},
                      "Volume On"
                    ),
                    span(
                      {class: "material-symbols-outlined"},
                      "volume_up"
                    )
                  )
                ) : isMobileOrTablet() ? "" : button(
                  {class: "font-rope text-sm sm:text-md font-semibold p-2 rounded-lg text-light bg-dark border-4 border-dark", onclick: () => {
                    muted.val = false;
                  }},
                  div(
                    {class: "flex flex-row gap-1"},
                    t(
                      {class: ""},
                      "Volume Off"
                    ),
                    span(
                      {class: "material-symbols-outlined"},
                      "volume_off"
                    )
                  )
                ),
                div({class: "h-4"})
              ),
              () => ProblemPicker({
                currentNumber: specifiedCaseNumber.val === -1 ? demoCaseNumber.val : specifiedCaseNumber.val,
                onProblemChosen: (caseNumber)=>{
                  route(`/case/${caseNumber}`);
                  window.location.reload();
                }
              })
            ),
            div({class: "sm:h-8 h-4"}),
            div(
              {class: "flex w-full flex-grow justify-center sm:max-h-[500px] max-h-[400px]"},
              div(
                {class: "flex flex-col-reverse  w-full sm:max-w-[75%] h-[300px] overflow-auto"},
                div({class: "flex flex-grow"}),
                chatOutputEl
              ),
            ),
            div({class: "h-4"}),
            div(
              {class: "flex w-full justify-center"},
              () => timerShow.val ? t(
                {class: "font-rope font-semibold"},
                ()=>timerText.val
              ) : ""
            ),
            div({class: "h-4"}),
            div(
              {class: "flex w-full justify-center"},
              textarea(
                {
                  id: "mainInput",
                  type: "text",
                  value: inputVal.val,
                  class: "w-full sm:max-w-[75%] text-center bg-steel p-2 rounded-lg focus:bg-light focus:border-4 focus:border-gold focus:outline-none",
                  disabled: inputDisabled.val,
                  placeholder: inputPlaceholder.val,
                }
              ) //mainInput
            ),
            div({class: "h-4"}),
            PrimaryButton(
              {onclick: () => {console.log("Pressed"); submitResponse()}, text: "Submit"}
            ),
            div({class: "h-4"}),
            div(
              {class: "flex w-full justify-center"},
              listenIndicatorShow.val ? listenIndicator : ""
            ),
            div({class: "h-4"}),
            audio({class: "hidden", id: "theAudio"}),//theAudio
          ),
          div({class: "flex flex-grow"})
        ),
        div({class: "flex flex-grow"}),
        div(
          {class: "flex w-full justify-center"},
          div(
            {class: "flex w-full sm:max-w-[75%] justify-end items-center"},
            t(
              {class: "font-rope text-dark sm:text-base text-sm"},
              "Whenever you think you're done, "
            ),
            div({class: "w-4"}),
            button(
              {
                class: "p-2 max-w-[200px] pl-4 pr-4 rounded-lg font-semibold font-rope  text-white bg-dark hover:bg-fire hover:text-light", 
                onclick: () => exitModalShow.val = true
              },
              "End Case" 
            )
          )
        )
      )
    }

  )
}

export default CasePage