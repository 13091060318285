
import van from "vanjs-core"
import {Router, route, url} from "./components/Router"
import HomePage from "./HomePage"
import CasePage from "./CasePage";

const {t, button, span} = van.tags;

console.log("Refreshing page");

// van.add(document.body, Timer({totalSecs: 5}))

van.add(document.body, Router());

window.addEventListener("popstate", (e) => {
  route(document.location.pathname);
});