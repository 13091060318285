import van from "vanjs-core"
import { initializeApp } from "firebase/app";
import { getFirestore, getDocs, collection, getCountFromServer, doc, getDoc, query, where  } from "firebase/firestore";
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged, signInAnonymously, linkWithCredential, linkWithPopup, updateProfile, getIdToken } from "firebase/auth";

import { fetchAndActivate, getRemoteConfig } from "firebase/remote-config";
import { getValue } from "firebase/remote-config";


const firebaseConfig = {
  apiKey: "AIzaSyBtgiCZjJRX9IXFsMdX7c9K9FKVwh7J0iI",
  authDomain: "casenow-2ceeb.firebaseapp.com",
  projectId: "casenow-2ceeb",
  storageBucket: "casenow-2ceeb.appspot.com",
  messagingSenderId: "530063755225",
  appId: "1:530063755225:web:a9ef8a93018d6a59cfbbc8",
  measurementId: "G-CRK54Z0C4T"
};

let demoCaseNumber = van.state(-1);
let specifiedCaseNumber = van.state(-1);
let maxDemoCaseNumber = van.state(0);
let maxCaseNumber = van.state(0);
let isMember = van.state(false);
let globalUserExam = van.state(null);


let app = initializeApp(firebaseConfig);
let auth = getAuth();

let globalUser = van.state(auth.currentUser);
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

remoteConfig.defaultConfig = {
  "maxDemoCaseNumber": 3,
  "maxCaseNumber":  10,
};


const transferFromAnonymous = async (oldUser, newUser) => {
  let oldToken = await getIdToken(oldUser);
  let newToken = await getIdToken(newUser);
  fetch("/api/transfer-account", {
    method: "POST",
    headers:{
        "Content-Type": "application/json" 
    },
    body: JSON.stringify({
      oldToken: oldToken,
      newToken: newToken,
    })
  });
}

const checkIsMember = async () => {
  if (globalUser.val == null){
    return;
  }
  let token = await getIdToken(globalUser.val);
  let initial = await fetch("/api/check-membership",{
    method: "POST",
    headers:{
        "Content-Type": "application/json" 
    },
    body: JSON.stringify({
      jwt: token
    })
  })
  let data = await initial.json()
  isMember.val = data?.result === "success";
   
  // const db = awaitgetFirestore(app);
  // console.log("UID: " , globalUser?.val?.uid)
  // let ref = await doc(db, "users", globalUser.val?.uid ?? "")
  // let snap = await getDoc(ref)
  // let proceed = snap.exists()
  // let data = snap.exists() ? snap.data() : {}
  // if (!snap.exists()){
  //   const q = query(collection(db, "users"), where("uid", "==", globalUser.val?.uid))
  //   let snapList = await getDocs(q);
  //   if (snapList.size > 0){
  //     proceed = true;
  //     data = snapList.docs.at(0).data()
  //   }
  // }
  // if (proceed){
  //   isMember.val = data?.isMember === true;
  //   console.log("Checking is member? ",  data?.isMember)
  // }
}

const checkForExams = async () => {
  globalUserExam.val = null;
  const db = await getFirestore(app);
  const currentTime = new Date();
  let q = query(
    collection(db, "exams"),
    where("invitedUsers", "array-contains", globalUser?.val?.email ?? ""),
  );
  let snaps = await getDocs(q);
  for (let snap of snaps.docs){
    let firstOneData = snap.data();
    let startDate = firstOneData["startTime"]?.toDate();
    let endDate = firstOneData["endTime"]?.toDate();
    let now = new Date();
    console.log("COMPLETED: ", firstOneData.completedUsers, globalUser?.val?.email)
    if (now >= startDate && now <= endDate && firstOneData.completedUsers.indexOf(globalUser?.val?.email) < 0){
      globalUserExam.val = firstOneData;
    }
  }
}

let db;
onAuthStateChanged(auth, (user) => {
  if (user) {
    if (globalUser?.val?.isAnonymous && user.email != null){
      // no await because we just let it happen in the background 
      // so account update is immediate on client side
      transferFromAnonymous(globalUser.val, user);
    }  
    globalUser.val = user;
    console.log("UID: ", globalUser?.val?.uid)
    console.log("GOOGLE? ", globalUser?.val?.email != null)
    if (globalUser?.val?.email != null){
      checkIsMember();
      checkForExams();
    }
  } else {
    globalUser.val = null;
    isMember.val = null;
    globalUserExam.val = null;
    signInAnonymously(auth);
  }
});

const signOutUser = async () => {
  await auth.signOut();
  await fetch("/api/sign-out");
}

const signInUserWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  await signInWithPopup(auth, provider);
}

const isSignedIn = () => {
  return globalUser.val != null && !(globalUser?.val?.isAnonymous ?? true);
}

const getDemoCaseData = async () => {
  auth = getAuth();
  try{
    demoCaseNumber.val = 1;
    await fetchAndActivate(remoteConfig);
    maxDemoCaseNumber.val = getValue(remoteConfig, "maxDemoCaseNumber").asNumber();
    maxCaseNumber.val = getValue(remoteConfig, "maxCaseNumber").asNumber();
  }
  catch(e){
    console.log("Something went wrong when counting available cases");
  }
}

let speechRecognition;
window.onload =  async () => {
  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  speechRecognition = new SpeechRecognition();
  speechRecognition.continuous = true;
  speechRecognition.interimResults = true;
  auth = getAuth();
  db = await getFirestore(app);
}

const retrieveTranscript = async (caseData) => {
  db = await getFirestore(app)

  console.log(caseData?.transcriptId);
  let ref1 = doc(db, "transcripts", caseData?.transcriptId ?? "")
  let snap1 = await getDoc(ref1)
  if (snap1.exists()){
    return snap1.data()
  }
  else{
    console.log("Bad doc id on trying to get transcript");
  }

  return {}
}



const setKeyDownListener = (callback)=>{
  document.onkeydown = (event) => {
    callback(event);
  }
}

const setKeyUpListener = (callback)=>{
  document.onkeyup = (event)=>{
    callback(event);
  }
}

export {firebaseConfig, app, demoCaseNumber, maxCaseNumber, isMember, maxDemoCaseNumber, getDemoCaseData, speechRecognition, setKeyDownListener, setKeyUpListener, specifiedCaseNumber, globalUser, signOutUser, signInUserWithGoogle, isSignedIn, retrieveTranscript, globalUserExam};