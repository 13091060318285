const formatElapsedTimeForCasing = (t) => {
  return (Math.round(t * 10) / 10).toFixed(1) + " s";
}

const formatMessageForChat = (msg) => {
  return msg.replace(/<INTERVIEWER>:/g, "");
}

const formatNameForProfileIcon = (name) => {
  let words = name.trim().split(" ");
  if (words.length == 0){
    return "P";
  }
  if (words.length == 1){
    return words[0];
  }
  try{
    return words[0][0] + words[1][0];
  }   
  catch(_){
    return "P";
  }
}

export {formatElapsedTimeForCasing, formatMessageForChat, formatNameForProfileIcon}