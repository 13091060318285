import van from "vanjs-core"
import { globalUserExam } from "../globals";
import { SmallLink } from "./SmallLink";
import { route } from "./Router";
const {div, button, t} = van.tags;

const ExamInvite = () => {
  return button(
      {
        class: "flex flex-col gap-1 p-4 bg-powder rounded-lg",
        onclick: () => {
          route(globalUserExam?.val?.problemNumber ? `/case/${globalUserExam?.val?.problemNumber}` : "/tbd")
           window.location.reload()
        }
      },
      t(
        {class: "font-rope font-semibold font-dark bg-powder"},
        `You have been invited by ${globalUserExam.val.hostOrg} to complete a case: '${globalUserExam.val.name}'`
      ),
      t(
        {class: "font-rope font-dark bg-powder"},
        `Case opened at ${globalUserExam.val.startTime.toDate().toLocaleTimeString('en-US', {
          month: 'short', 
          day: '2-digit', 
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        })} and you must start before ${globalUserExam.val.endTime.toDate().toLocaleTimeString('en-US', {
          month: 'short', 
          day: '2-digit', 
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        })} to participate`
      ),
      t(
        {class: "font-rope font-dark font-semibold hover:underline underline-offset-8 bg-powder"},
        "Click here to begin"
      )
    )
}

export {ExamInvite}